<template>
  <div class="card">
    <p class="percent" v-if="isFeatured">
      {{ isFeatured ? final.offerIsFeatured : '' }}
    </p>
    <div class="info-wrapper">
      <div class="mob">
        <img class="logo" :src="logo" height="70" :alt="title" />
        <div class="mob_info">
          <h4 class="chance">
            Вероятность <span>{{ chance }}%</span>
          </h4>
          <h6 v-if="isFeatured">
            {{ isFeatured ? final.offerIsFeatured : '' }}
          </h6>
          <h3 class="amount">{{ header }} ₽</h3>
        </div>
        <p class="sub-header">{{ subHeader }}</p>
      </div>
      <a :href="link" target="_blank" class="link" @click="sendClickId(id, clickId)">{{
        common.btnsText.getBorrow
      }}</a>
      <p class="sub-header ads" v-html="linkValue"></p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoanCard',
  props: [
    'header',
    'subHeader',
    'logo',
    'isFeatured',
    'link',
    'org',
    'title',
    'chance',
    'common',
    'final',
    'id',
    'clickId'
  ],
  methods: {
    makeid(length) {
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    sendClickId(id, clickId) {
      axios.get(`/api/stat_vitrina?id=${id}&clickId=${clickId}`)
    }
  },
  computed: {
    linkValue() {
      const linkParams = new URL(this.link).searchParams;
      const param = linkParams.get('erid')
        ? linkParams.get('erid').split('?')[0]
        : 'LjN8K' + this.makeid(4);

      return `Реклама ${this.org} <br>Erid: ${param}`;
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/main.scss';

.card {
  width: 100%;
  background-color: $white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 10px;

  @media (max-width: 640px) {
    overflow: inherit;
  }

  .percent {
    width: 100%;
    min-height: 50px;
    height: 100%;
    font-family: 'Montserrat-Medium', 'Roboto', 'sans-serif';
    font-size: 15px;
    color: $green-dark;
    line-height: 50px;
    border-bottom: 1px solid #b0b0b0;
    text-align: center;
    margin: 0;

    @media (max-width: 640px) {
      display: none;
    }
  }

  .info-wrapper {
    @media (max-width: 640px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .logo {
    margin: 20px auto;
    display: block;
    height: 70px;
    object-fit: scale-down;
    max-width: 100%;
  }

  .mob {
    display: block;

    h6 {
      color: $green-dark;
      text-align: center;
      font-size: 13px;
      margin: 0;
      display: none;

      @media (max-width: 640px) {
        display: block;
      }
    }

    @media (max-width: 640px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mob_info {
        width: 40%;
      }

      h3 {
        margin: 0;
        font-size: 22px;
        line-height: 28px;
      }

      p {
        margin: 0;
        width: 30%;
        font-size: 14px;
        line-height: 18px;
      }

      img {
        margin: 0;
        width: 30%;
        height: 50px;
      }
    }

    @media (max-width: 480px) {
      h3 {
        font-size: 18px;
        line-height: 22px;
      }

      p {
        font-size: 13px;
        line-height: 16px;
      }

      img {
        height: 40px;
      }
    }

    @media (max-width: 400px) {
      .mob_info {
        margin: 0 5px;
      }
    }

    @media (max-width: 360px) {
      h3 {
        font-size: 14px;
        line-height: 18px;
      }

      h6 {
        font-size: 12px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
      }

      img {
        height: 36px;
      }
    }
  }

  .chance {
    text-align: center;
    color: $black-text;
    font-size: 16px;
    letter-spacing: 1px;
    margin: 10px 0;

    span {
      font-weight: bold;
      font-family: 'Montserrat-Bold';
      color: $green-dark;
      font-size: 24px;
      line-height: 24px;
      display: block;
    }

    @media (max-width: 640px) {
      max-width: 140px;
      margin: -40px auto 0;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 5px;
      box-shadow: 0px -10px 20px rgb(0 0 0 / 5%);
    }

    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 16px;

      span {
        font-size: 20px;
        line-height: 20px;
      }
    }

    @media (max-width: 380px) {
      font-size: 12px;
    }
  }

  .amount {
    font-family: 'Montserrat-SemiBold', 'Roboto', 'sans-serif';
    font-size: 35px;
    color: $black-text;
    line-height: 50px;
    margin: 0 auto;
    text-align: center;
  }

  .sub-header {
    font-family: 'Montserrat-Medium', 'Roboto', 'sans-serif';
    font-size: 16px;
    color: transparentize($black-text, 0.4);
    line-height: 16px;
    margin: 15px auto;
    text-align: center;
  }

  .ads {
    font-size: 8px;
    line-height: 14px;
    margin: 6px auto;
  }

  .link {
    @extend %btn-gradient;
    @extend %btn-small;
    max-width: 240px;
    width: 100%;
    margin: 30px auto 20px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

    @media (max-width: 640px) {
      max-width: 100%;
      margin-bottom: 10px;
    }

    @media (max-width: 480px) {
      margin-top: 15px;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
</style>
