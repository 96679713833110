<template>
  <article>
    <h2 class="title">{{ common.popups.popupAcceptance.title }}</h2>
    <p class="text" v-html="checkCookie ? common.popups.popupAcceptance.text : text"></p>
  </article>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: 'popupAcceptance',
  props: ['common'],
  data() {
    return {
      text: 'Совершая любые действия на сайте вы даете <a href="/api/get-doc/pdpp" target="_blank">Согласие на обработку персональных данных</a>.'
    }
  },
  computed: {
    checkCookie() {
      return (
        Cookies.get('sbg-cpa') !== undefined || Cookies.get('CPA') !== undefined
      );
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.title {
  @extend %popup-title;
  margin: 20px 0 10px;
}

.text {
  @extend %popup-text;
  margin: 10px 0 20px;
}
</style>
